<template>
  <div>
    <title>SIM INVENTORY ~ ADD MASTER BUYER DATA</title>
    <section class="content-header">
      <h1>
        Add Master Buyer Data
        <br />
        <h4>Please enter master buyer data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Master Buyer Data</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">List Master Buyer Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Buyer Code</label>
                  <input
                    type="text"
                    v-model="isidata.kodebuyer"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Buyer Code"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Buyer Name</label>
                  <input
                    type="text"
                    v-model="isidata.namabuyer"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Buyer Name"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Description</label>
                  <input
                    type="text"
                    v-model="isidata.notesbuyer"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Description"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                ><i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/buyer">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
        
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      loading: false,
      isidata: {
        kodebuyer: "",
        namabuyer: "",
        notesbuyer: ""
      }
    };
  },
  methods: {
    async saveData() {
      this.loading = true;
      var kode = this.isidata.kodebuyer;
      var nama = this.isidata.namabuyer;
      var notes = this.isidata.notesbuyer;
      if (kode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Buyer Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nama == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Buyer Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (notes == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Description can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          kode_buyer: this.isidata.kodebuyer,
          name: this.isidata.namabuyer,
          notes: this.isidata.notesbuyer,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPISaveData = "http://26.183.23.191/inventory/backend/inventory/api/buyer/savebuyer";
        const urlAPISaveData = this.$apiurl + "buyer/savebuyer";
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPISaveData, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.isidata.kodebuyer = "";
              this.isidata.namabuyer = "";
              this.isidata.notesbuyer = "";
              this.loading = false;
              this.$router.push({ name: "buyer" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "buyer" });
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
